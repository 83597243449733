//
// Variables
// --------------------------------------------------

//@font-face{
//  font-family:"HelveticaWeb";
//  src:url("/assets-min/fonts/helvetica/05598d95-f297-4c7f-a825-ab617e7e045f.eot?#iefix");
//  src:url("/assets-min/fonts/helvetica/05598d95-f297-4c7f-a825-ab617e7e045f.eot?#iefix") format("eot"),url("/assets-min/fonts/helvetica/89294146-207c-4803-91fe-338f0d0094bd.woff2") format("woff2"),url("/assets-min/fonts/helvetica/57055fe4-c2d3-4287-bac2-eece43fdcbd3.woff") format("woff"),url("/assets-min/fonts/helvetica/2922d55c-8df4-4066-b7b0-eec2d1524330.ttf") format("truetype"),url("/assets-min/fonts/helvetica/f8343ddf-e94d-471e-95b0-3b435acc2499.svg#f8343ddf-e94d-471e-95b0-3b435acc2499") format("svg");
//}
//@font-face{
//  font-family:"HelveticaWeb";
//  font-weight: bold;
//  src:url("/assets-min/fonts/helvetica/05f47e74-d17c-45af-86c3-8ab878fd4ec7.eot?#iefix");
//  src:url("/assets-min/fonts/helvetica/05f47e74-d17c-45af-86c3-8ab878fd4ec7.eot?#iefix") format("eot"),url("/assets-min/fonts/helvetica/7ce0a2f5-eb00-46aa-919c-5b3f3667646c.woff2") format("woff2"),url("/assets-min/fonts/helvetica/cad22c74-45b4-4c49-9e6a-0cd3768a7bc7.woff") format("woff"),url("/assets-min/fonts/helvetica/8ebb9b04-d12d-488e-aa96-0bb6e6777336.ttf") format("truetype"),url("/assets-min/fonts/helvetica/7672ef16-fedc-4b60-8337-04b537a6ada5.svg#7672ef16-fedc-4b60-8337-04b537a6ada5") format("svg");
//}
@font-face{
  font-family:"Helvetica Rounded";
  src:url("/assets-min/fonts/helvetica/b6a7814a-12d6-461d-a382-42d8ec08c211.eot?#iefix");
  src:url("/assets-min/fonts/helvetica/b6a7814a-12d6-461d-a382-42d8ec08c211.eot?#iefix") format("eot"),url("/assets-min/fonts/helvetica/97a9fecf-7aea-42d4-a440-4930f8f5f463.woff2") format("woff2"),url("/assets-min/fonts/helvetica/a2911332-2a58-407b-9a36-16b5a2578d81.woff") format("woff"),url("/assets-min/fonts/helvetica/86ccf671-266b-4ee6-8570-5ae9c23df7ea.ttf") format("truetype"),url("/assets-min/fonts/helvetica/85924d99-8c32-4fc4-9d9e-01a9c4e6a023.svg#85924d99-8c32-4fc4-9d9e-01a9c4e6a023") format("svg");
}
@font-face{
  font-family:"Helvetica Rounded";
  font-style: italic;
  src:url("/assets-min/fonts/helvetica/9f58ff6e-0b04-4604-8a6e-b7be1e80000a.eot?#iefix");
  src:url("/assets-min/fonts/helvetica/9f58ff6e-0b04-4604-8a6e-b7be1e80000a.eot?#iefix") format("eot"),url("/assets-min/fonts/helvetica/a53d2685-a767-4ef3-a00c-96ab1fc4ad92.woff2") format("woff2"),url("/assets-min/fonts/helvetica/362149fe-9886-40e0-822e-fd8acf5c7099.woff") format("woff"),url("/assets-min/fonts/helvetica/af5addf7-e2da-41a5-8d2d-245b63a55fe6.ttf") format("truetype"),url("/assets-min/fonts/helvetica/8125ad76-c22a-4b3c-aba6-6e3e37b96d44.svg#8125ad76-c22a-4b3c-aba6-6e3e37b96d44") format("svg");
}


// Global values
// --------------------------------------------------

// Custom
// -------------------------

@css-blue: 				#009ee0;
@css-red: 				#e30613;
@css-yellow: 			#ffe800;
@css-darkred: 			#cf0019;
@css-darker-red: #b30018;
@css-green: #5cb85c;
@btn-blue:                      #1571b7;
@btn-blue-darker:               darken(@btn-blue, 15%);;


// Grays
// -------------------------

@gray-darker:            lighten(#000, 13.5%); // #222
@gray-dark:              lighten(#000, 20%);   // #333
@gray:                   lighten(#000, 33.5%); // #555
@gray-light:             lighten(#000, 60%);   // #999
@gray-lighter:           lighten(#000, 93.5%); // #eee

// Brand colors
// -------------------------

@brand-primary:         @css-red;
@brand-success:         #5cb85c;
@brand-warning:         #f0ad4e;
@brand-danger:          #d9534f;
@brand-info:            #5bc0de;
@white: 				#ffffff;
@black: 				#000000;

// Social media colors
// -------------------------

@brand-facebook:         #3b63b6;
@brand-twitter:          #56c2ea;
@brand-youtube:          #E52D27;
@brand-flickr:           #FF0084;
@brand-google:           #DD4B39;
@brand-instagram:        #3F729B;
@brand-pinterest:        #e53e59;
@brand-linkedin:         #0976B4;
@brand-trip-advisor: 	 #04973c;

// Scaffolding
// -------------------------

@body-bg:               #fff;
@text-color:            @gray-dark;

// Links
// -------------------------

@link-color:            @css-red;
@link-hover-color:      darken(@link-color, 15%);

// Typography
// -------------------------


@font-family-sans-serif:  "Helvetica", "HelveticaWeb", Arial, sans-serif;


@font-family-serif:       Georgia, "Times New Roman", Times, serif;
@font-family-monospace:   Monaco, Menlo, Consolas, "Courier New", monospace;
@font-family-base:        @font-family-sans-serif;

@font-size-base:          16px;
@font-size-large:         ceil(@font-size-base * 1.25); // ~18px
@font-size-small:         ceil(@font-size-base * 0.85); // ~12px

@line-height-base:        1.5;
@line-height-computed:    floor(@font-size-base * @line-height-base); // ~20px

@headings-font-family:    "Helvetica Rounded", Helvetica, Arial, sans-serif;

@headings-font-weight:    300;
@headings-line-height:    1.1;


// Assets folder
@assets_img_folder: '/assets-min/images/';

// Iconography
// -------------------------

@icon-font-path:          "/vendor/bootstrap/fonts/";
@icon-font-name:          "glyphicons-halflings-regular";


// Components
// -------------------------
// Based on 14px font-size and 1.428 line-height (~20px to start)

@padding-base-vertical:          6px;
@padding-base-horizontal:        12px;

@padding-large-vertical:         10px;
@padding-large-horizontal:       16px;

@padding-small-vertical:         5px;
@padding-small-horizontal:       10px;

@line-height-large:              1.33;
@line-height-small:              1.5;

@border-radius-base:             6px;
@border-radius-large:            6px;
@border-radius-small:            3px;

@component-active-bg:            @brand-primary;

@caret-width-base:               4px;
@caret-width-large:              5px;

// Tables
// -------------------------

@table-cell-padding:                 8px;
@table-condensed-cell-padding:       5px;

@table-bg:                           transparent; // overall background-color
@table-bg-accent:                    #f9f9f9; // for striping
@table-bg-hover:                     #f5f5f5;
@table-bg-active:                    @table-bg-hover;

@table-border-color:                 #ddd; // table and cell border


// Buttons
// -------------------------

@btn-font-weight:                700;

@btn-default-color:              #333;
@btn-default-bg:                @css-yellow;
@btn-default-border:             @css-yellow;

@btn-primary-color:              #fff;
@btn-primary-bg:                 @brand-primary;
@btn-primary-border:             darken(@btn-primary-bg, 5%);

@btn-success-color:              #fff;
@btn-success-bg:                 @brand-success;
@btn-success-border:             darken(@btn-success-bg, 5%);

@btn-warning-color:              #fff;
@btn-warning-bg:                 @brand-warning;
@btn-warning-border:             darken(@btn-warning-bg, 5%);

@btn-danger-color:               #fff;
@btn-danger-bg:                  @brand-danger;
@btn-danger-border:              darken(@btn-danger-bg, 5%);

@btn-info-color:                 #fff;
@btn-info-bg:                    @brand-info;
@btn-info-border:                darken(@btn-info-bg, 5%);

@btn-link-disabled-color:        @gray-light;


// Forms
// -------------------------

@input-bg:                       #fff;
@input-bg-disabled:              @gray-lighter;

@input-color:                    @gray;
@input-border:                   #ccc;
@input-border-radius:            @border-radius-base;
@input-border-focus:             #66afe9;

@input-color-placeholder:        @gray-light;

@input-height-base:              (@line-height-computed + (@padding-base-vertical * 2) + 2);
@input-height-large:             (floor(@font-size-large * @line-height-large) + (@padding-large-vertical * 2) + 2);
@input-height-small:             (floor(@font-size-small * @line-height-small) + (@padding-small-vertical * 2) + 2);

@legend-color:                   @gray-dark;
@legend-border-color:            #e5e5e5;

@input-group-addon-bg:           transparent;
@input-group-addon-border-color: transparent;


// Dropdowns
// -------------------------

@dropdown-bg:                    #fff;
@dropdown-border:                rgba(0,0,0,.15);
@dropdown-fallback-border:       #ccc;
@dropdown-divider-bg:            #e5e5e5;

@dropdown-link-active-color:     #fff;
@dropdown-link-active-bg:        @component-active-bg;

@dropdown-link-color:            @gray-dark;
@dropdown-link-hover-color:      #fff;
@dropdown-link-hover-bg:         @dropdown-link-active-bg;

@dropdown-link-disabled-color:   @gray-light;

@dropdown-header-color:          @gray-light;

@dropdown-caret-color:           #000;


// COMPONENT VARIABLES
// --------------------------------------------------


// Z-index master list
// -------------------------
// Used for a bird's eye view of components dependent on the z-axis
// Try to avoid customizing these :)

@zindex-navbar:            1000;
@zindex-dropdown:          1000;
@zindex-popover:           1010;
@zindex-tooltip:           1030;
@zindex-navbar-fixed:      1030;
@zindex-modal-background:  1040;
@zindex-modal:             1050;

// Media queries breakpoints
// --------------------------------------------------

// Extra small screen / phone
@screen-xs:                  480px;
@screen-phone:               @screen-xs;

// Small screen / tablet
@screen-sm:                  768px;
@screen-tablet:              @screen-sm;

// Medium screen / desktop
@screen-md:                  992px;
@screen-desktop:             @screen-md;

// Large screen / wide desktop
@screen-lg:                  1200px;
@screen-lg-desktop:          @screen-lg;

// So media queries don't overlap when required, provide a maximum
@screen-xs-max:              (@screen-sm - 1);
@screen-sm-max:              (@screen-md - 1);
@screen-md-max:              (@screen-lg - 1);


// Grid system
// --------------------------------------------------

// Number of columns in the grid system
@grid-columns:              12;
// Padding, to be divided by two and applied to the left and right of all columns
@grid-gutter-width:         30px;
// Point at which the navbar stops collapsing
@grid-float-breakpoint:     @screen-desktop;


// Navbar
// -------------------------

// Basics of a navbar
@navbar-height:                    50px;
@navbar-margin-bottom:             @line-height-computed;
@navbar-default-color:             #ffffff;
@navbar-default-bg:                @css-red;
@navbar-default-border:            @css-darkred;
@navbar-border-radius:             @border-radius-base;
@navbar-padding-horizontal:        10px;
@navbar-padding-vertical:          8px;

// Navbar links
@navbar-default-link-color:                @white;
@navbar-default-link-hover-color:          @white;
@navbar-default-link-hover-bg:             darken(@navbar-default-bg, 8%);
@navbar-default-link-active-color:         @white;
@navbar-default-link-active-bg:            @css-darkred;
@navbar-default-link-disabled-color:       #ccc;
@navbar-default-link-disabled-bg:          transparent;

// Navbar brand label
@navbar-default-brand-color:               @navbar-default-link-color;
@navbar-default-brand-hover-color:         darken(@navbar-default-link-color, 10%);
@navbar-default-brand-hover-bg:            transparent;

// Navbar toggle
@navbar-default-toggle-hover-bg:           darken(@navbar-default-bg, 4%);
@navbar-default-toggle-icon-bar-bg:        #850010;
@navbar-default-toggle-border-color:       #ddd;


// Inverted navbar
//
// Reset inverted navbar basics
@navbar-inverse-color:                      @gray-light;
@navbar-inverse-bg:                         #222;
@navbar-inverse-border:                     darken(@navbar-inverse-bg, 10%);

// Inverted navbar links
@navbar-inverse-link-color:                 @gray-light;
@navbar-inverse-link-hover-color:           #fff;
@navbar-inverse-link-hover-bg:              transparent;
@navbar-inverse-link-active-color:          @navbar-inverse-link-hover-color;
@navbar-inverse-link-active-bg:             darken(@navbar-inverse-bg, 10%);
@navbar-inverse-link-disabled-color:        #444;
@navbar-inverse-link-disabled-bg:           transparent;

// Inverted navbar brand label
@navbar-inverse-brand-color:                @navbar-inverse-link-color;
@navbar-inverse-brand-hover-color:          #fff;
@navbar-inverse-brand-hover-bg:             transparent;

// Inverted navbar search
// Normal navbar needs no special styles or vars
@navbar-inverse-search-bg:                  lighten(@navbar-inverse-bg, 25%);
@navbar-inverse-search-bg-focus:            #fff;
@navbar-inverse-search-border:              @navbar-inverse-bg;
@navbar-inverse-search-placeholder-color:   #ccc;

// Inverted navbar toggle
@navbar-inverse-toggle-hover-bg:            #333;
@navbar-inverse-toggle-icon-bar-bg:         #fff;
@navbar-inverse-toggle-border-color:        #333;


// Navs
// -------------------------

@nav-link-padding:                          10px 15px;
@nav-link-hover-bg:                         @gray-lighter;

@nav-disabled-link-color:                   @gray-light;
@nav-disabled-link-hover-color:             @gray-light;

@nav-open-link-hover-color:                 #fff;
@nav-open-caret-border-color:               #fff;

// Tabs
@nav-tabs-border-color:                     transparent;

@nav-tabs-link-hover-border-color:          transparent;

@nav-tabs-active-link-hover-bg:             @white;
@nav-tabs-active-link-hover-color:          @text-color;
@nav-tabs-active-link-hover-border-color:   transparent;

@nav-tabs-justified-link-border-color:            transparent;
@nav-tabs-justified-active-link-border-color:     @white;

// Pills
@nav-pills-active-link-hover-bg:            @component-active-bg;
@nav-pills-active-link-hover-color:         #fff;


// Pagination
// -------------------------

@pagination-bg:                        #fff;
@pagination-border:                    #ddd;

@pagination-hover-bg:                  @gray-lighter;

@pagination-active-bg:                 @css-red;
@pagination-active-color:              #fff;

@pagination-disabled-color:            @gray-light;


// Pager
// -------------------------

@pager-border-radius:                  15px;
@pager-disabled-color:                 @gray-light;


// Jumbotron
// -------------------------

@jumbotron-padding:              30px;
@jumbotron-color:                inherit;
@jumbotron-bg:                   @gray-lighter;

@jumbotron-heading-color:        inherit;


// Form states and alerts
// -------------------------

@state-warning-text:             #c09853;
@state-warning-bg:               #fcf8e3;
@state-warning-border:           darken(spin(@state-warning-bg, -10), 3%);

@state-danger-text:              #b94a48;
@state-danger-bg:                #f2dede;
@state-danger-border:            darken(spin(@state-danger-bg, -10), 3%);

@state-success-text:             #468847;
@state-success-bg:               #dff0d8;
@state-success-border:           darken(spin(@state-success-bg, -10), 5%);

@state-info-text:                #3a87ad;
@state-info-bg:                  #d9edf7;
@state-info-border:              darken(spin(@state-info-bg, -10), 7%);


// Tooltips
// -------------------------
@tooltip-max-width:           200px;
@tooltip-color:               #fff;
@tooltip-bg:                  #000;

@tooltip-arrow-width:         5px;
@tooltip-arrow-color:         @tooltip-bg;


// Popovers
// -------------------------
@popover-bg:                          #fff;
@popover-max-width:                   276px;
@popover-border-color:                rgba(0,0,0,.2);
@popover-fallback-border-color:       #ccc;

@popover-title-bg:                    darken(@popover-bg, 3%);

@popover-arrow-width:                 10px;
@popover-arrow-color:                 #fff;

@popover-arrow-outer-width:           (@popover-arrow-width + 1);
@popover-arrow-outer-color:           rgba(0,0,0,.25);
@popover-arrow-outer-fallback-color:  #999;


// Labels
// -------------------------

@label-default-bg:            @gray-light;
@label-primary-bg:            @brand-primary;
@label-success-bg:            @brand-success;
@label-info-bg:               @brand-info;
@label-warning-bg:            @brand-warning;
@label-danger-bg:             @brand-danger;

@label-color:                 #fff;
@label-link-hover-color:      #fff;


// Modals
// -------------------------
@modal-inner-padding:         20px;

@modal-title-padding:         15px;
@modal-title-line-height:     @line-height-base;

@modal-content-bg:                             #fff;
@modal-content-border-color:                   rgba(0,0,0,.2);
@modal-content-fallback-border-color:          #999;

@modal-backdrop-bg:           #000;
@modal-header-border-color:   #e5e5e5;
@modal-footer-border-color:   @modal-header-border-color;


// Alerts
// -------------------------
@alert-padding:               15px;
@alert-border-radius:         @border-radius-base;
@alert-link-font-weight:      bold;

@alert-success-bg:            @state-success-bg;
@alert-success-text:          @state-success-text;
@alert-success-border:        @state-success-border;

@alert-info-bg:               @state-info-bg;
@alert-info-text:             @state-info-text;
@alert-info-border:           @state-info-border;

@alert-warning-bg:            @state-warning-bg;
@alert-warning-text:          @state-warning-text;
@alert-warning-border:        @state-warning-border;

@alert-danger-bg:             @state-danger-bg;
@alert-danger-text:           @state-danger-text;
@alert-danger-border:         @state-danger-border;


// Progress bars
// -------------------------
@progress-bg:                 #f5f5f5;
@progress-bar-color:          #fff;

@progress-bar-bg:             @brand-primary;
@progress-bar-success-bg:     @brand-success;
@progress-bar-warning-bg:     @brand-warning;
@progress-bar-danger-bg:      @brand-danger;
@progress-bar-info-bg:        @brand-info;


// List group
// -------------------------
@list-group-bg:               #fff;
@list-group-border:           #ddd;
@list-group-border-radius:    @border-radius-base;

@list-group-hover-bg:         #f5f5f5;
@list-group-active-color:     #fff;
@list-group-active-bg:        @component-active-bg;
@list-group-active-border:    @list-group-active-bg;

@list-group-link-color:          #555;
@list-group-link-heading-color:  #333;


// Panels
// -------------------------
@panel-bg:                    #fff;
@panel-inner-border:          #ddd;
@panel-border-radius:         2px;
@panel-footer-bg:             #f5f5f5;

@panel-default-text:          @gray-dark;
@panel-default-border:        #ddd;
@panel-default-heading-bg:    #EFEFEF;

@panel-primary-text:          #fff;
@panel-primary-border:        @brand-primary;
@panel-primary-heading-bg:    @brand-primary;

@panel-success-text:          @state-success-text;
@panel-success-border:        @state-success-border;
@panel-success-heading-bg:    @state-success-bg;

@panel-warning-text:          @state-warning-text;
@panel-warning-border:        @state-warning-border;
@panel-warning-heading-bg:    @state-warning-bg;

@panel-danger-text:           @state-danger-text;
@panel-danger-border:         @state-danger-border;
@panel-danger-heading-bg:     @state-danger-bg;

@panel-info-text:             @state-info-text;
@panel-info-border:           @state-info-border;
@panel-info-heading-bg:       @state-info-bg;


// Thumbnails
// -------------------------
@thumbnail-padding:           4px;
@thumbnail-bg:                @body-bg;
@thumbnail-border:            #ddd;
@thumbnail-border-radius:     @border-radius-base;

@thumbnail-caption-color:     @text-color;
@thumbnail-caption-padding:   9px;


// Wells
// -------------------------
@well-bg:                     #f5f5f5;


// Badges
// -------------------------
@badge-color:                 #fff;
@badge-link-hover-color:      #fff;
@badge-bg:                    @gray-light;

@badge-active-color:          @link-color;
@badge-active-bg:             #fff;

@badge-font-weight:           bold;
@badge-line-height:           1;
@badge-border-radius:         10px;


// Breadcrumbs
// -------------------------
@breadcrumb-bg:               #f5f5f5;
@breadcrumb-color:            #ccc;
@breadcrumb-active-color:     @gray-light;


// Carousel
// ------------------------

@carousel-text-shadow:                        0 1px 2px rgba(0,0,0,.6);

@carousel-control-color:                      #fff;
@carousel-control-width:                      15%;
@carousel-control-opacity:                    .5;
@carousel-control-font-size:                  20px;

@carousel-indicator-active-bg:                #fff;
@carousel-indicator-border-color:             #fff;

@carousel-caption-color:                      #fff;


// Close
// ------------------------
@close-color:                 #000;
@close-font-weight:           bold;
@close-text-shadow:           0 1px 0 #fff;


// Code
// ------------------------
@code-color:                  #c7254e;
@code-bg:                     #f9f2f4;

@pre-bg:                      #f5f5f5;
@pre-color:                   @gray-dark;
@pre-border-color:            #ccc;
@pre-scrollable-max-height:   340px;

// Type
// ------------------------
@text-muted:                  @gray-light;
@abbr-border-color:           @gray-light;
@headings-small-color:        @gray-light;
@blockquote-small-color:      @gray-light;
@blockquote-border-color:     @gray-lighter;
@page-header-border-color:    @gray-lighter;

// Miscellaneous
// -------------------------

// Hr border color
@hr-border:                   @gray-lighter;

// Horizontal forms & lists
@component-offset-horizontal: 180px;


// Container sizes
// --------------------------------------------------

// Small screen / tablet
@container-tablet:            ((720px + @grid-gutter-width));

// Medium screen / desktop
@container-desktop:           ((940px + @grid-gutter-width));

// Large screen / wide desktop
@container-lg-desktop:        ((940px + @grid-gutter-width));
