@import "../vendor/bootstrap/less/bootstrap";
@import "../vendor/fontawesome/less/font-awesome";
// @import "fonts";
@import "variables";
@import "tel-picker.less";

body{
  overflow-x: hidden;
}

// @import "../vendor/weather-icons/css/weather-icons.css";

/*
* Vendor specific
*/
// @import "vendors/parsley";
// @import "vendors/slick";

@alert-success: @brand-success;
@primary: @brand-primary;
@fa-font-path: "/assets-min/fonts";
@fa-css-prefix: fa;

.nav .fa-angle-down {color:@css-yellow; font-weight:bold};

.margin-attr{
  .product-mini.media-primary{
    min-height: 144px;
  }
}

//@font-face{
//    font-family:"Helvetica Rounded LT W01 Black";
//    src:url("/assets-min/fonts/helvetica/3b7902cc-32fc-4d03-a073-85c9cf3c1b11.eot?#iefix");
//    src:url("/assets-min/fonts/helvetica/3b7902cc-32fc-4d03-a073-85c9cf3c1b11.eot?#iefix") format("eot"),url("/assets-min/fonts/helvetica/6f8c93ab-8471-44ad-b174-535bbfb4143d.woff2") format("woff2"),url("/assets-min/fonts/helvetica/db21eb82-5556-4c2f-a386-74077df41c1d.woff") format("woff"),url("/assets-min/fonts/helvetica/a612fe51-0f96-4122-bf27-18ceefe708a1.ttf") format("truetype"),url("/assets-min/fonts/helvetica/a8272ae2-9da7-43c8-b776-80d89d2ea22a.svg#a8272ae2-9da7-43c8-b776-80d89d2ea22a") format("svg");
//}
//@font-face{
//    font-family:"Helvetica Rounded LT W01BlkObl";
//    src:url("/assets-min/fonts/helvetica/f6804de9-7804-408a-82e1-b8b434aeab52.eot?#iefix");
//    src:url("/assets-min/fonts/helvetica/f6804de9-7804-408a-82e1-b8b434aeab52.eot?#iefix") format("eot"),url("/assets-min/fonts/helvetica/ea4a868f-b102-495f-ad66-99ca0f92c752.woff2") format("woff2"),url("/assets-min/fonts/helvetica/cf3b87ab-9ab1-4eca-8f25-936a0240712b.woff") format("woff"),url("/assets-min/fonts/helvetica/71fd3624-1996-4eda-b752-445263fa7cf4.ttf") format("truetype"),url("/assets-min/fonts/helvetica/da68853f-a6bf-43eb-a1fe-d211d32f5308.svg#da68853f-a6bf-43eb-a1fe-d211d32f5308") format("svg");
//}
//@font-face{
//    font-family:"Helvetica Rounded LT W01 Bd Cn";
//    src:url("/assets-min/fonts/helvetica/41e1c9cc-c256-4891-a7fe-995f736e471c.eot?#iefix");
//    src:url("/assets-min/fonts/helvetica/41e1c9cc-c256-4891-a7fe-995f736e471c.eot?#iefix") format("eot"),url("/assets-min/fonts/helvetica/5bb19385-3d9b-4206-baa2-11387f6ab7bd.woff2") format("woff2"),url("/assets-min/fonts/helvetica/4d11364c-0cb8-4ecb-bd2f-069303a189b5.woff") format("woff"),url("/assets-min/fonts/helvetica/f6cca164-0a65-4490-8aeb-6e9ce8103c2d.ttf") format("truetype"),url("/assets-min/fonts/helvetica/a9e34d2a-2b40-4288-942f-de81748a19f1.svg#a9e34d2a-2b40-4288-942f-de81748a19f1") format("svg");
//}
//@font-face{
//    font-family:"Helvetica Rounded LTW01BdCnObl";
//    src:url("/assets-min/fonts/helvetica/fd4c3183-6d68-4c70-9728-418b95747619.eot?#iefix");
//    src:url("/assets-min/fonts/helvetica/fd4c3183-6d68-4c70-9728-418b95747619.eot?#iefix") format("eot"),url("/assets-min/fonts/helvetica/9109013d-673a-4099-a499-66828a623988.woff2") format("woff2"),url("/assets-min/fonts/helvetica/5bbec031-d4ec-4157-901d-7edc38a00eb4.woff") format("woff"),url("/assets-min/fonts/helvetica/ff57f352-6f92-4d75-b064-ccf2b75bac6d.ttf") format("truetype"),url("/assets-min/fonts/helvetica/49be136c-0398-485d-8e16-84be2ced399c.svg#49be136c-0398-485d-8e16-84be2ced399c") format("svg");
//}


html, body {
  height: 100%;
}

// .article-image.with-padding{

//   padding-right: 15px;
// }

.attractions_banner{
  background: @css-darkred;
  padding:10px;
  margin-bottom: 20px;
  color: #fff;
  .main-banner{
    font-size: 32px;

  }

  .sub-banner{

    padding-bottom: 10px;
  }
}

.col-md-4.margin-attr{
  margin-bottom: 25px;
}

.attraction_title{
  font-size: 15px;
  margin: 5px 0;
  font-weight: bold;
  line-height: 18px;
  .badge.product-alert{
    color: #fff;
    background: red;
    margin-left: 5px;
    display: inline-block;
    font-size: 12px;
    padding: 5px;
  }
  span{
    font-size: 13px;
    padding-top: 10px;
    color: #cf0019;
  }
}

.city_attractions{
  height: 300px;
  overflow: hidden;
  position: relative;

  .overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.3);
  }
}



#container {
  min-height: 100%;
  margin-bottom: -44px;
  padding-bottom: 44px;
}

#sidebar {
  padding-top: 1 * @font-size-h1;
}

// headings

h1 {font-size:32px};
h2 {font-size:26px};


.mt-1 {
  margin-top: @line-height-computed * 1px;
}

.mt-2 {
  margin-top: @line-height-computed * 2px;
}

.mt-3 {
  margin-top: @line-height-computed * 3px;
}

.mb-1 {
  margin-bottom: @line-height-computed * 1;
}

.mb-2 {
  margin-bottom: @line-height-computed * 2;
}

.mb-3 {
  margin-bottom: @line-height-computed * 3;
}

.align-right {
  float: right;
}

.align-left {
  float: left;
}

.align-right, .align-left {
  @media (max-width: @screen-xs) {
    padding-left: 0;
    padding-right: 0;
    float: none;
  }
}

.clear-both {
  clear: both;
}

.clear-left {
  clear: left;
}

.clear-right {
  clear: right;
}

.text-align-center {
  text-align: center;
}

.vertical-align-middle {
  position: relative;
  top: 50%;
  .translate(0%, -50%);
}

.horizontal-align-center {
  .translate(-50%, -50%);
  left: 50%;
  position: absolute;
  z-index: 99;
}

.caption {
  font-size: @font-size-small;
  min-height: @font-size-small;
  box-sizing: content-box;

  p {
    margin-bottom: 0;
  }

  .article-image-attribution {
    float: right;
  }
}


.jumbotron {
  *:last-child {
    margin-bottom: 0;
  }
}

.page-title {
  text-align: center;
  margin-top: @font-size-h1;
  margin-bottom: @font-size-h1;

  &.page-title-left {
    text-align: left;
  }
}

.page-header {
  margin-top: @font-size-h1;
  h1, h2, h3 {
    margin-top: 0;
  }
}

.article {
  padding-bottom: @grid-gutter-width;

  .article-title {
    &:extend(.page-title);
  }

  .article-byline {
    position: relative;
    text-align: center;
    margin-bottom: @font-size-h1;
  }

  h2 {
    margin-bottom: 0.5em;
  }
}

.meta-byline {
  margin-bottom: @padding-base-vertical;
}

.article-image {
  margin-bottom: @line-height-computed;

  .caption {
    .clearfix();
    padding: 9px;
  }
}

[class*="col-"].align-left {
  padding-left: 0;
  padding-right: 15px;

  @media (max-width: @screen-md) {
    padding-right: 0;
  }
}

[class*="col-"].align-right {
  padding-right: 0;
  padding-left: 15px;
  @media (max-width: @screen-md) {
    padding-left: 0;
  }
}

[class*="col-"].align-center {
  text-align: center;
  .caption {
    text-align: left;
  }
  .thumbnail {
    display: inline-block;
  }
}

.article-image.align-full {

}

.article-image.align-left, .article-image.align-right {

  @media (min-width: @screen-xs) {
    max-width: 50%;
  }

  @media (max-width: @screen-xs) {
    padding-left: 0;
    padding-right: 0;
  }

}

.article-body {
  .clearfix();
}


// Media extends
.media {
  border: 1px solid @gray-light;
  padding: @panel-body-padding;
  margin-bottom: @font-size-base;
  border-radius: @border-radius-small;

  .media-right,
  .media > .pull-right {
    padding-left: @panel-body-padding;
    padding-bottom: @panel-body-padding;
  }

  .media-left,
  .media > .pull-left {
    padding-right: @panel-body-padding;
    padding-bottom: @panel-body-padding;
  }

  .media-date-byline {
    border-top: 1px solid @gray-light;
    border-bottom: 1px solid @gray-light;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-bottom: @padding-base-vertical;
  }

  .media-summary {
    p:last-child {
      margin-bottom: @padding-base-vertical;
    }
  }

  &.media-responsive {
    .media-right, .media-left {

      @media (max-width: @screen-xs) {
        display: block;
        float: none !important;
        padding-right: 0;
        padding-left: 0;

        .media-object {
          width: 100%;
        }
      }

      @media (min-width: @screen-xs + 1) {
        .media-object {
          width: 200px;
        }
      }

      @media (min-width: @screen-md) {
        .media-object {
          width: 160px;
        }
      }

    }
  }

  &-galleries {
    padding: 4px;
    background-color: #fff;
    .media-summary {
      padding: @padding-small-vertical;
    }
  }
}

.nav-stacked {
  .nav-stacked {
    > li > a {
      padding-left: 40px;
    }
  }
}

.edit-this-page {
  font-size: 14px !important;
  background: #fff;
  display: inline-block;
  padding: 4px;
  height: 22px;
  width: 22px;
  line-height: 14px;
  color: @gray;

  &:hover {
    background: @gray;
    color: #fff;
  }
}

#footer {
  background: @css-red;
}

// Nav bar dropdown

#header {
  //background: blue;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;

  .navbar {
    position: relative;
    margin-bottom: 0;
    border-radius: 0;
    z-index: 2;
  }

  transition: top 250ms ease-out;

  .navbar-brand {
    transition: height 250ms ease-out;
  }

  .navbar-toggle {
    margin-right:0;
  }
}
@media (min-width: 992px) {
  .navbar-nav>li>a {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 15px;
  }
}

.header-info {
  position: relative;
  z-index: 1;
  background: @css-yellow;
  color: @css-darker-red;
  font-family: @headings-font-family;
  padding: 5px;
  text-align: center;
  box-shadow: 0 0 5px rgba(0,0,0,0.5);
  transition: top 250ms ease-out;
  top: 0;

  .fa {
    font-size: 20px;
    position: relative;
    top: 1px;
  }
}

body.shrink {
  #header {
    top: -36px;

    .navbar-brand {
      // transform: scale3d(0.75, 0.75, 1);
      height: 56px;
    }

    #main-menu {
      //margin-top: 15px;
    }
  }
  #content {
    top: -36px;
  }
  .header-info {
    top: -34px;
  }
}
body.shrink.after-shrink-animation {
  .header-info {
    display: none;
  }
}
#content {
  position: relative;
  top: 0;
  transition: top 250ms ease-out;
}

.navbar-nav > li.dropdown {
  > a {
    padding-right: 30px;
    &:after {
      .fa-icon();
      content: @fa-var-chevron-down;
      position: absolute;
      top: ~"calc(50% - 6px)";
      right: 9px;
      font-size: 0.75em;
      color:@css-yellow;
    }
  }
}

// Nav form

.navbar-form {
  .form-control {
    background: transparent;
    border: 0;
    border-bottom: 1px solid @gray-light;
    border-radius: 0;
    color: @gray-lighter;
    box-shadow: none;
  }
}

// share

.article-sharing {
  min-height: 22px;
  margin-left: -5px;
}

.share-btn {
  display: inline-block;
  margin: 0 5px;
  cursor: pointer;
  color: @text-color;
  font-size: 14px;
}

// List group extends
.list-group-item-meta {
  font-size: @font-size-small;
}

/* notifications */
#notifications {
  z-index: 101;
}

body.ltr #notifications {
  left: 0;
}

body.rtl #notifications {
  right: 0;
}

#notifications.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0;
}

#notifications {
  text-align: center;

  .notification {
    .alert;
  }
  .notification[class*="message"] {
  }
  .notification[class*="notice"] {
    .alert-info;
  }
  .notification[class*="error"] {
    .alert-danger;
  }

  .notification {
    margin-bottom: 0;
    border-radius: 0;

    a {
      color: inherit;
      &:hover {
        text-decoration: none;
      }
    }
  }

  .notification + .notification {
    border-top: 0;
  }
}

// Dropdown sub-menu

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px;
  border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.dropdown-submenu > a:after {
  .fa-icon();
  content: @fa-var-chevron-right;
  position: relative;
  top: ~"calc(50% - 6px)";
  right: -9px;
  font-size: 0.75em;
}

.dropdown-submenu:hover > a:after {
  border-left-color: #fff;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}

h1.gtmwarning {
  text-align: center;
  background: red;
}

/* Gallery */

.grid-sizer, .grid-item {
  width: 33%;
  margin-bottom: 5px;

}

.gallery-image {
  padding: 5px;
  width: 33%;
  float: left;
  position: relative;
}

.gallery-image-link {
  display: block;
  position: relative;
  padding-bottom: 75%;
  overflow: hidden;

  .img-responsive {
    position: absolute;
    .square(100%);
  }
  .caption {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    bottom: 0;
    padding: 5px;
    color: #fff;
  }
}

.grid-item-width2 {
  width: percentage(8/12);
}

.grid-item-height2 {
  img {
    width: 100%;
    overflow: hidden;
  }
  .lightbox {
    padding-bottom: 100%;
  }
}

.gallery-link {
  position: relative;

  &:hover .caption {
    display: block;
  }

  .caption {
    display: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: #ffffff;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
  }

}

.mfp-description {
  color: #ccc;
  margin-top: 10px;
}

a.thumbnail:hover, a.thumbnail:active {
  text-decoration: none;
}


// Form extends

.field.form-group {
  position: relative;
}

.sprout-email-button {
  padding: 0 !important;
  right: 6px !important;
  bottom: 11px !important;
  margin: 0 !important;
  line-height: 1 !important;
}

.field .instructions {
  color: lighten(@text-color, 25%);
  font-size: @font-size-small;
  margin-bottom: 5px;
  *:last-child {
    margin-bottom: 0;
  }
}

.control-label, label {
  + .instructions {
    margin-top: -5px;
  }
}

label.required:after {
  content: ' *';
  color: @brand-danger;
}

.stars {
  .fa-star {color:@css-red; margin-right:3px;}
}

/// CitySightSeeing specific



.nav {
  // font is too bold
  // font-family:@headings-font-family;
}
#primary-navbar {
  position: relative;
}
#right-menu {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 3px 6px 3px 6px;
  .btn, .btn .fa {
    color: @css-darker-red;
  }
  a:not(.btn) {
    font-size: 14px;
    line-height: 18px;
    padding-right: 10px;

    &:hover {
      text-decoration: none;
    }

    .fa {
      font-size: 20px;
      position: relative;
      top: 2px;
    }
  }

}
#header-cart-total-items, #header-cart-total-items-mobile {
  display: inline-block;
  color: @brand-primary;
  font-size: 12px;
  font-weight:bold;
  background: #fff;
  border-radius: 5px;

  padding: 2px 4px;
}
#header-cart-total-items-mobile {
  font-size: 16px;
  position: relative;
  top: -4px;
}
#primarynav {
  color:#EFEFEF;
  box-shadow: 0 0 5px rgba(0,0,0,0.5);

  font-family: @headings-font-family;

  //.navbar {
  //  height:90px;
  //}

  .navbar-header {

  }

  .navbar-brand {
    position: relative;
    background: url('../images/css_logo.svg') no-repeat;
    display: block;
    float: left;
    width: 188px;
    height: 70px;
    margin: 10px;
    z-index: 3;
  }

  #main-menu {
    margin-top: 28px;
  }

  .navbar-toggle {
    border:none !important;
  }

  .navbar-toggle .icon-bar {
    background-color:white;
  }

  .navbar-toggle {
    .icon-bar {color:white;}
    margin-top:17px;
    .fa-bars {color:white;}
    
  }
  .navbar-collapse {
    border:none !important;
  }

}


.shopping-cart-block {

  min-width: 320px;
  max-width: 445px;
  transition: all .3s;
  top: 128px;
  right: 0;
  position: fixed;
  z-index: 99999;
  display: none;
  &.dropped{
    display: block;
  }
  .upsell-row{
    display: none;
  }
  .panel-checkout{
    table tr td, table tr th {
      padding: 3px 15px;
      table{
        tr td, tr th{
          padding: 3px;
        }
      }
    }
  }
}

// customer care bar
#content {
  position: relative;
  margin-top:130px;
}


.site-switcher {
  color:white;
  background: @css-darker-red;
  font-weight: bold;

  height: 36px;
  //padding: 0 5px;
  
  .customer-care-number {
    display: inline-block;
  }

  .site-switcher-right {
    float: right;
    line-height: 36px;
  }

  .site-city-tabs {
    float: left;
    a {
      display: inline-block;
      color: #fff;
      padding: 0 10px;
      height: 36px;
      line-height: 36px;
      font-size: 15px;
      &.active {
        background: @css-red;
      }
    }
  }

  .currency-switcher {
    a.active {
      background: @css-red;
      color: #fff;
    }
    margin-right:10px;
  }

  //.btn {
  //  border-radius: 0;
  //
  //  &.active {
  //    background: @css-red;
  //  }
  //}
}

.customer-care {
  color:white !important;

  a {
    color:white !important;
    text-decoration:none !important;
  }
}




.btn-thin {
  padding: 0 8px !important;
  border:none !important;
}

.btn-white {
  .button-variant(@css-darkred; white; white);
}

.btn-yellow {
  .button-variant(@css-darkred; @css-yellow; @css-yellow);
}

// Product page

.embed-responsive-thin {
  padding-bottom: 40%;
}

.fullWidthImageWithTextOverlay {
  padding:10px;
  background-size: cover;
  background-repeat:none;
  position:relative;

}

.sliderText {
  text-align:center;

  h1 {
    text-shadow: 1.5px 1.5px 7px #000;
    color:white !important;
    font-size: 32px;
    padding: 0 20px;
    margin-top:10px;
    margin-bottom:10px;

    a {
      color:white;
    }
  }



}

.segment-2-products .header-image-promo {
  .middle {
    background: radial-gradient(closest-side, rgba(0,0,0,0.3), rgba(0,0,0,0));
  }
  .sliderText {

    h1 {
      font-size: 36px;
      @media (min-width: @screen-sm) {
        font-size: 72px;
        margin-top: 2px;
      }
    }
    .lead {
      //border-top: 1px solid rgba(255,255,255,0.5);

      font-family: @headings-font-family;
      text-shadow: 1px 1px 3px #000;
      color: @css-yellow !important;
      padding: 0 20px;

      margin: 0 auto;
      font-weight: normal;

      @media (min-width: @screen-sm) {
        font-size: 24px;
        padding: 0 40px;
        max-width: 75%;
      }

    }

    .pre-lead {
      font-family: @headings-font-family;
      text-shadow: 1px 1px 3px #000;
      color: @gray-lighter !important;
      font-size: 20px;
      padding: 0 40px;
      margin:0;
    }
  }

}

.product-categories {

  padding-left:25px; 
  list-style:none;

  li { margin-bottom:2px; }

  li:before {    
    font-family: FontAwesome;
    content: "\f00c";
    color:#5cb85c;
    margin:0 5px 0 -22px;
  }

  li.product-categories-child {
    padding-left:30px;
  }

  li.product-categories-child:before {
    content: "\f061";
  }
  


}




.booknow-text {font-size:20px; font-weight:bold}
.booknow-price {font-size:16px;}

.bg-grey {
  background-color: #EFEFEF;
}

// .product-mini {
//   position:relative;
// }


.welcome-block {
  //width: 100%;
  height: 500px;
  //padding-bottom: 33%;
  .panel-block {
    width: 100%;
    height: auto;

    .slide-spacer {
      padding-bottom: 66%;
      @media (min-width: @screen-md) {
        padding-bottom: 44%;
      }
      @media (min-width: @screen-lg) {
        padding-bottom: 33%;
      }
    }
  }
}
.slideshow-holder {
  position: relative;

  .arrow-append {
    position: absolute;
    right: 0;
    top: 50%;
    z-index: 999;
    .slick-prev {
      display: none;
      visibility: hidden;
    }
    .slick-next {
      padding: 48px 10px;
      background-color: #f0f0f0;
      right: 0;
      top: 50%;
      // .transform(0%, -50%);
      margin-top: 0;
      width: auto;
      height: auto;
      color: #393939;
      font-size: 30px;
      &:before {
        content: normal;
      }
    }
    &.arrow-append-feature {
      .opacity(0.8);
    }
  }
}

.slide-item {
  box-sizing: border-box;
  padding-bottom: 25%;
  position: relative;
}


.slider-general {
  @media (min-width: @screen-md) {
    margin-bottom: 90px;
  }
  .slick-dots {
    li {
      width: 20px;
      height: 20px;
      padding: 0;
    }
    button {
      background-color: transparent;
      width: 15px;
      height: 15px;
      padding: 0;
      border: 3px solid @css-red;
      border-radius: 100%;
    }
    li.slick-active button, button:hover {
      background-color: @css-red;
    }
  }
}




.wrap-around-container {
  position: absolute;
  top:0px;
  //width:100%;
  margin-top: 20px;
}
.wrap-around-label {
  background: @css-yellow;
  padding: 2px 40px 2px 20px;
  margin-left: -20px;
  margin-top: 0;
  position: relative;
  display:inline-block;
  color: #454545;

  // SHADOW Doesn't work on polygon clip path
  -moz-box-shadow: 1px 1px 3px #292929;
  -webkit-box-shadow: 1px 1px 3px #292929;
  box-shadow: 1px 1px 3px #292929;

  // border-top-right-radius: 5px;
  // border-bottom-right-radius: 5px;

  // these are percentage based so could pose problems if our widths are very different

// -webkit-clip-path: polygon(100% 0, 90% 50%, 100% 100%, 0% 100%, 0 50%, 0% 0%);
// clip-path: polygon(100% 0, 90% 50%, 100% 100%, 0% 100%, 0 50%, 0% 0%);
}






.wrap-around-label-arrow {
  width: 0; height: 0;
  line-height: 0;
  border-left: 20px solid transparent;
  border-top: 10px solid darken(@css-yellow,10%);
  top: 104%;
  left: 0;
  position: absolute;
}

.sub-list-unstyled {
  .list-unstyled;
}

.social-share-list {
  font-size:14px;
}

#footer {
  background: @css-red;
  color:white;
  font-size:12px;

  .help-block {
    color:white;
  }

  ul {

    &.first-level {
      li {font-size:12px;}
    }

    &.second-level {
      li {font-size:12px;}
    }
    li {

      a {
        color:white;
      }
    }
  }

}


.product-mini {
  .fa-check {
    color: @css-green;
  }
}



.social-share-list {
  .list-inline();
  .fa-stack {
    // width: 3em;
  }
  li {
    margin-bottom: 10px;
    a {
      padding: 0 0;
    }
  }

  .social-share {
    .btn();
    .btn-default();
    &.facebook {
      color: @brand-facebook;
      &:hover {
        color: darken(@brand-facebook, 8%);
      }
    }
    &.twitter {
      color: @brand-twitter;
      &:hover {
        color: darken(@brand-twitter, 8%);
      }
    }
    &.google {
      color: @brand-google;
      &:hover {
        color: darken(@brand-google, 8%);
      }
    }
    &.linkedin {
      color: @brand-linkedin;
      &:hover {
        color: darken(@brand-linkedin, 8%);
      }
    }
    &.pinterest {
      color: @brand-pinterest;
      &:hover {
        color: darken(@brand-pinterest, 8%);
      }
    }
    &.instagram {
      color: @brand-instagram;
      &:hover {
        color: darken(@brand-instagram, 8%);
      }
    }
    .share-total {
      margin-left: 5px;
      color: #000;
    }
  }

  .social-share-stacked {
    cursor: pointer;
    &.facebook {
      .fa-circle {
        color: @brand-facebook;
      }
      &:hover .fa-circle {
        color: darken(@brand-facebook, 8%);
      }
    }
    &.twitter {
      .fa-circle {
        color: @brand-twitter;
      }
      &:hover .fa-circle {
        color: darken(@brand-twitter, 8%);
      }
    }
    &.google {
      .fa-circle {
        color: @brand-google;
      }
      &:hover .fa-circle {
        color: darken(@brand-google, 8%);
      }
    }
    &.linkedin {
      .fa-circle {
        color: @brand-linkedin;
      }
      &:hover .fa-circle {
        color: darken(@brand-linkedin, 8%);
      }
    }
    &.pinterest {
      .fa-circle {
        color: @brand-pinterest;
      }
      &:hover .fa-circle {
        color: darken(@brand-pinterest, 8%);
      }
    }
    &.instagram {
      .fa-circle {
        color: @brand-instagram;
      }
      &:hover .fa-circle {
        color: darken(@brand-instagram, 8%);
      }
    }
    &.trip-advisor {
      .fa-circle {
        color: @brand-trip-advisor;
      }
      &:hover .fa-circle {
        color: darken(@brand-trip-advisor, 8%);
      }
    }
  }

  margin-bottom: 0px;
}

.social-text {
  margin-top: 5px;
  font-size: 13px;
  color: @text-color;
  line-height: 1;
  text-transform: uppercase;
}



// Meta data top of articles etc
//------------------------------------
.media-meta {
  list-style: none;
  padding: 0;

  margin-bottom: @line-height-base;
  font-size: @font-size-small;
  color: @gray;

  .date {
    font-weight: bold;
  }

  li {
    display: block;
    float: left;
    padding-right: 20px;
  }


  .clearfix;
}


/* Dealing with article images */


.article-image {
  padding: 0; // We need to override the grid padding on images, e.g. <img class="img article-image col-sm-6">
  margin-top: 4px; // adjust to ensure top of image in line with top of text
  .mb-1;

  // &.center-block {
  //   margin: 10px 0;
  // }
}

.article-image, .image-group-grid {
  .caption {
    background-color: #f3f3f3;
    padding: 15px;
    font-weight: bold;
    display: block;

    font-size: @font-size-small;

    p {
      margin-bottom: 0;
      line-height: 16px;
    }
  }
}


// instead of using background image, use an absolutely positioned image

.header-image-promo {
  height:500px;
}

.psuedo-background-img-container {
  position: relative;
  width:100%;
  overflow: hidden;
}
.psuedo-background-img-new {
  position: absolute;
  width: 1920px;
  height: 500px;

}

.psuedo-background-img {
  background:#CCC;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


// Consider changing for mobile?

.verticallyCenteredBlock {

  .outer {
    display: table;
    position: absolute;
    height: 100%;
    width: 100%;
  }
  
  .middle {
    display: table-cell;
    vertical-align: middle;
  }
  
  .inner {
    margin-left: auto;
    margin-right: auto; 
    width: /*whatever width you want*/;
  }
}

// home page selector
.nav-wrapper {
  border: 2px solid blue;
  //&:after {
  //  content: "";
  //  display: table;
  //  clear: both;
  //}
}
.nav-wrapper-mobile {
  @media (min-width: @screen-sm) {

  }
}
.nav-wrapper-desktop {
  @media (min-width: @screen-sm) {
  }
}

body.city-selector {

  // No search, no cart  
  .btn-strip-nav {display:none !important;}

  //.site-switcher {display:none;}

  .navbar-header {
    margin-left:~'calc(50% - 94px)';
    float:none;
  }

  .no-gutter {
    padding:0 !important;
  }

  #primarynav .navbar-collapse,
  #primarynav .navbar-toggle {
    display: none !important;
  }

  #primarynav {
    margin-bottom:0 !important;
  }

  .selection-box {
    .clearfix;
  }
  
  .city-select {
    height:225px;
    cursor:pointer;
    overflow:hidden;

    @media (min-width: @screen-sm) {
      height:500px;

      img {

      left:-10px; //blur has strange endgets
      filter: blur(5px);
      width:110%;
      transition: .5s ease-in-out;
      
    }

    &:hover img {
      filter: blur(0);

      transition: .5s ease-in-out;
    }

  }


}





}

@media (min-width: @screen-sm) {

  .sliderText {

    text-align:center;

    h1 {
      font-size:50px;
      padding: 0 20px;
    }

  }

  .dropdown-menu {
    min-width:220px;
  }

  .fullWidthImageWithTextOverlay {
    .embed-responsive;
    .embed-responsive-thin;
  }



  .bottomCenteredBlock {

    .outer {
      display: table;
      position: absolute;
      height: 100%;
      width: 100%;
    }

    .middle {
      display: table-cell;
      vertical-align: bottom;
    }

    .inner {
      margin-left: auto;
      margin-right: auto; 
      width: 80%;
    }
    
  }


}


#footer {

}


.navbar-right {
  margin-right:0;
}

.btn-strip-nav, .navbar-right {
  .btn {
    margin: 0;
  }
  a, .fa {
    color: @white;
  }
  .fa-phone {
    padding-top: 4px;
  }
}

.btn-strip-nav-mobile {
  a, .fa {
    //font-size: 26px;
    color: @white;

  }
  .fa {
    font-size: 26px;
  }
  a {
    margin-right: 10px;
  }
}
.mobile-timetable-link {
  a {
    color: #fff;
  }
}
.btn-strip-nav {
  .pull-right;
  margin-top: 29px;
  .tickets-item {
    font-size: 14px;
  }
}



#search_bar {
  color: #fff;

  .search_box {
    background-color: rgba(0, 0, 0, 0.3);
    padding: 20px;
    // border-bottom: 1px solid @white;
  }
  .close {
    opacity:0.75;
    font-size: 20px;
    color: #fff;
    a {
      color: white;
      &:hover {
        text-decoration: none;
        color: darken(@white, 10%);
      }

      i {
        font-size: 16px;
      }
    }
  }

  .looking_for_bar {
    .btn {
      margin-bottom:5px;
    }
  }
  .form-control {
    color: black;
    font-size: 16px;
    &:focus {
      color: black;
    }
    &::placeholder {
      color: rgb(145, 144, 144);
    }
    &:-ms-input-placeholder {
      color: rgb(145, 144, 144);
    }
  }
  .looking_for_bar {
    margin-top: 10px;
    span {
      font-size: 16px;
    }
  }
}


.stop-sidebar {
  h4 {
    font-size: 18px;
    border-top: 1px solid #cccccc;
    padding-top: 25px;
    padding-bottom: 10px;
  }
  .no-br {
    br {
      display: none;
    }
  }
}


// #content {padding-top:90px}


// FLEX STYLES USED FOR STOP SIDEBAR
// CLEAN UP OR RIP OUT LATER




.flex-flow(@property) {
  -webkit-flex-flow: @property;
  -moz-flex-flow: @property;
  -ms-flex-flow: @property;
  flex-flow: @property;
}

.stop-flex {
  .display-flex;
  .align-items(flex-start);
  position: relative;
  .card {
    .mb-2();
    width: 100%;
    border-bottom: none;
    border: 1px solid #f6f6f6;
    position: relative;
    box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.2), 0 0px 2px 0 rgba(0, 0, 0, 0.19);
    .summary-content, .card-holder {
      padding: 15px;
    }
    .media-heading {
      margin-bottom: 10px;
    }
    .summary-holder {
      width: 100%;
      .btn-group {
        margin: 0;
        width: 100%;
        .btn {
          width: 50%;
        }
      }
      .summary-content {
        display: none;
        margin: 0;
        line-height: 1.4;
      }
    }
  }
  .circle-number, .tour-circles-holder {
    margin-top: 15px;
    margin-right: 15px;
  }
}



.listing-group {
  margin: 25px 0;
  a.list-flex {
    &:hover, &:focus {
      background: rgba(0, 0, 0, .05);;
    }
  }
  .list-flex {
    .display-flex;
    padding: 40px 10px;
    border-bottom: 1px solid #e5e5e5;
    .row-picture {
      .flex(none);
      width: 60px;
      height: 60px;
    }
    .row-picture, .list-colour-strip {
      &.pull-left {
        margin-right: 20px;
      }
    }
    .list-button {
      text-align: center;
    }
    .btn {
      margin: 0;
      margin-top: 10px;
    }
    .list-button, .row-picture, .list-colour-strip {
      .flex(none);
    }
    .row-content {
      .display-flex;
      .align-items(center);
    }
    .content {
      margin-right: 10px;
      p {
        line-height: 1.4;
        margin: 5px 0;
      }
    }
    &.listing-stop {

      .row-content {
        display: block;
        .row-picture {
          display: block;
          float: none;
          margin: 0 auto;
          margin-bottom: 10px;
        }
      }
    }
  }
  .list-group-item-heading, .list-group-item-text {
    color: @text-color;
  }
  p {
    margin: 0;
    color: @text-color;
  }
  .row-content {
    min-height: 45px;
  }
  .list-group-separator {
    margin: 30px 0;
    &:before {
      width: 100%;
    }
  }
}



.stop-sidebar {
  h4 {
    font-size: 18px;
    border-top: 1px solid #cccccc;
    padding-top: 25px;
    padding-bottom: 10px;
  }
  .no-br {
    br {
      display: none;
    }
  }
}


.display-flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.flex(@columns: initial) {
  -webkit-flex: @columns;
  -moz-flex: @columns;
  -ms-flex: @columns;
  flex: @columns;
}

.justify-content(@property) {
  -webkit-justify-content: @property; /* Safari 6.1+ */
  -moz-justify-content: @property;
  -ms-justify-content: @property;
  justify-content: @property;
}

.align-items(@property) {
  -webkit-align-items: @property;
  -moz-align-items: @property;
  -ms-align-items: @property;
  align-items: @property;
}

.flex-flow(@property) {
  -webkit-flex-flow: @property;
  -moz-flex-flow: @property;
  -ms-flex-flow: @property;
  flex-flow: @property;
}


.flex-list {
  .display-flex;
  .flex-flow(row);
  margin-bottom: 15px;
  .flex-icon {
    margin-right: 20px;
    width: 30px;
    .flex(none);
    text-align: center;
    .fa {
      font-size: 2em;
    }
    .fa-clock-time {
      background: url('../images/clock-icon-01.svg') no-repeat;
      display: block;
      width: 26px;
      height: 26px;
      margin: 0 auto;
    }
    .fa-discount {
      background: url('../images/discount.svg') no-repeat;
      display: block;
      width: 26px;
      height: 26px;
      margin: 0 auto;
    }
  }
  .flex-content {
    margin-top: 2px;
  }
}

// SHOP
// SHOP
// SHOP


/*
 Shop ======================
 */

 .ticket-item {
  //background: blue;
  position: relative;

  background: white;

  .media-body {
    padding: 15px;
  }
  .media-body p {
    margin-bottom: 20px;
  }

  .ticket-img {
    margin-right: 10px;
    float: left;
  }

  .ticket-img {
    width: 80px;
  }

  .ticket-title {
    width: 100%;
    margin: 0;
    padding-bottom: 10px;
    h3 {
      margin-top: 0;
      margin-bottom: 0px;
      font-size: 20px;

      .label {
        font-weight: normal;
        font-size: 14px;
        padding: 5px 6px;
        background: #d4d4d4;
      }
    }
  }

  .ticket-img {
    float: left;
  }
  .ticket-price {
    text-align: right;
    .ticket-price-item {
      display: block;
      border: 1px solid #ccc;
      padding: 5px 10px;
      border-radius: 5px;
      .price-value {
        font-weight: bold;
      }
    }
  }

  .price-table td small {
    color: red;
  }

  .ticket-body {
    padding-left: 100px;
  }

  @media (max-width: @screen-xs-max) {
    .ticket-body {
      padding-left: 0;
      padding-right: 0;
    }

    .ticket-img {
      float: none;
    }

    .ticket-title {
      margin-top: 10px;
    }

  }


  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

}

#tickets-jhb, #tickets-cpt {
  .modal-title {
    font-size: 24px;
  }
}


// Iphone 5 basically
@media (max-width: 320px) {

  .navbar-brand {
    width:140px !important;
  }
  .site-switcher {

    a {
      font-size:11px;

    }
  }

}

@media (max-width: 480px) {
  .mobile-table,
  .mobile-table > thead,
  .mobile-table > thead > th,
  .mobile-table > tbody,
  .mobile-table > tbody > tr,
  .mobile-table > tbody > tr > td,
  .mobile-table > tbody > tr > th {
    font-size: 13px;
  }

  th.td-mobile, td.td-mobile {
    width: 100% !important;
    padding: 4px !important;
    text-align: left
  }


  tr {
    clear: both;
  }

  .media-left,
  .media-right,
  .media-body {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    margin-bottom: 15px;
  }

  .media-left {
    margin-bottom: 15px;
  }

  .buyNowBox {

    .form-control[type=number] {
     padding:0 !important;
     min-width:20px !important;
   }

 }


}

.numeric {
  text-align: right;
}

.tour-list {
  list-style: none;
  padding-left: 0px;
  .tour-circle {
    display: inline-block;
    margin-bottom: -2px;
  }
  li {
    display: inline-block;
  }
}

.ui-datepicker {
  background: #fff;
  &.ui-datepicker-inline {
    width: 100%;
  }
  table.ui-datepicker-calendar {
    td {
      span, a {
        text-align: center;
      }
    }
  }
}

[data-spinner-plus], [data-spinner-minus] {
  cursor: pointer;
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
}

.parsley-errors-list {
  margin: 0;
  margin-top: 5px;
  padding: 0;
  list-style-type: none;

  li {
    font-size: 14px;
    color: @brand-danger;
  }
}

.parsley-errors-list.filled {
  opacity: 1;
}


.buyNowBox {
  background:rgb(251, 248, 203);
  padding:20px;
  box-shadow: 0 0 0 #000;
  transform: translate3d(0,0,0) // remember to add vendor prefixes

}

.buyNowReminder {
  position:absolute;
  top:1px;
  right:15px;
  box-shadow: 0 0 0 #000;
  transform: translate3d(0,0,0); // remember to add vendor prefixes

  #buyNowReminderButton {
    width: 100%;
    display:block !important;
    border-radius:0 !important;
    background: @css-red;
    border:none;
    box-shadow: 0 0 0 #000;
    border-bottom: 1px solid @css-darker-red;
    border-left: 1px solid @css-darker-red;
    border-right: 1px solid @css-darker-red;
    transform: translate3d(0,0,0); // remember to add vendor prefixes
    padding: 3px 12px;
  }

  // background:rgb(251, 248, 203);
  // color:black;
  // font-size:20px;
  // padding:5px 20px;

}





.intl-tel-input {
  width: 100%;
}

.iti-flag {background-image: url("/assets-min/images/flags.png");}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .iti-flag {background-image: url("/assets-min/images/flags@2x.png");}
}





.general-tabs {
  background: transparent;
  -webkit-box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.12);
  box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.12);
  li a {
    color: @text-color;
    margin: 0;
    border: 0;
  }
  li:hover, li.active {
    border-bottom: 2px solid @css-blue;
  }
  .search-tab:hover, .search-tab:focus {
    border: 0;
  }
  .search-tab {
    float: right;
    border: 0;
    padding: 0 6px;
    .input-group-addon {
      width: auto;
    }
    .btn-white {
      margin-top: -1px;
    }
  }
  .navbar-form {
    margin: 0;
    padding: 0;
    margin-bottom: -1px;
  }
  > li > a {
    padding: 10px 10px;
  }
}

.ui-datepicker td.ui-within-period a {
  background: #ffe800;
}

.mega-dropdown-menu {
  width: 800px;
  .dropdown-column {
    width: 266px;
    float: left;

    .stop-number {
      border-radius: 50%;
      display: inline-block;
      width: 20px;
      height: 20px;
      background: #a5a5a5;
      color: #fff;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
    }

    a {
      display: block;
      padding: 3px 20px;
      clear: both;
      font-weight: normal;
      line-height: @line-height-base;
      color: @dropdown-link-color;
      white-space: nowrap; // prevent links from randomly breaking onto new lines

      &:hover,
      &:focus {
        text-decoration: none;
        color: @dropdown-link-hover-color;
        background-color: @dropdown-link-hover-bg;
      }

      display: block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  @media (max-width: @grid-float-breakpoint) {
    width: 100%;
    float: none;
    .dropdown-column {
      width: 100%;
      float: none;

      a {
        color: #fff;
      }
    }
  }
}

// badges for comparison of packages

.product-alert {
  color:white; 
  background:red; 
  margin-left:5px; 
  display:inline-block; 
  font-size:12px; 
  padding:5px;

}

.current-selection {
 color:white; 
 mix-blend-mode: multiply;
 background:red; 
  //  opacity:0.5;
  margin-left:5px; 
  display:inline-block; 
  font-size:12px; 
  padding:5px;
}


[data-forex-rates] {
  font-size: 13px;

  td {
    font-size: 13px;
  }
}

.ticket-price {
  font-weight: 700;
}

[data-forex-rates] {

  .forex-rate {

    line-height: 1;
    font-size: 12px;
      display:none; // jquery shows

      border: 1px solid #ccc;
      opacity:0.75;
      border-radius: 4px;
      padding: 4px 4px;
      
      &:before {

        font-family: FontAwesome;
        content: "\f1ec";
        opacity:0.5;

      }
    }

    &.inline {
      display:inline-block;
    }


  }


  .cart-readonly .remove-ticket-button {
    display: none;
  }

  .stop-sidebar {
    h4 {
      font-size: 18px;
      border-top: 1px solid #cccccc;
      padding-top: 25px;
      padding-bottom: 10px;
    }
    .no-br {
      br {
        display: none;
      }
    }
  }

  .panel-checkout {
    &, input {
      font-size: 14px;
    }
    .panel-body {
      padding: 5px;
    }
    table tr td, table tr th {
      padding: 3px;
    }
  }

  .btn:not(.btn-link):not(.btn-flat) {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }



// Print helper

@media print {
  .no-print {
    display: none !important;
  }
}

// Formstack embeds

.fsBody.fsEmbed {
  width: 100% !important;
  padding: 0px !important;
}

.fsBody .fsForm {
  margin: 0px auto !important;
  padding: 5px !important;
}



// TMAC screens


body.segment-2-standard-screen {
  background:#eae8e8;
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.cableway-holder {

  height: 100vh;
  width: 100%;
  text-transform: uppercase;

  .cableway-panel {
    position: relative;
    width: 1920px;
    transform-origin: center left;
    top: 50%;
    transform: translateY(-50%);
  }

  p {
    margin: 0;
  }
  p.large {
    font-family: 'HelveticaWeb', sans-serif;
    font-weight: 700;
    font-size: 120px;
    text-align: center;
  }
  p.medium {
    font-family: 'HelveticaWeb', sans-serif;
    font-weight: 500;
    font-size: 36px;
    text-align: center;
  }

  .inforow-holder {
    background: #fff;

  }

  .status-heading {
    left: 0;
    font-family: 'HelveticaWeb', sans-serif;
    font-weight: 700;
    font-size: 100px;
    line-height: 100px;
    text-align: center;
    width: 100%;
  }

  .inforow {
    width: 1920px;
    //margin-left: auto;
    //margin-right: auto;
    text-align: center;
    border-bottom: 1px solid #eae8e8;
    font-size: 36px;
  }

  .red {
    background: @css-red;
    color: #ffffff;
  }

  .row1 {
    height: 55px;
  }

  .row2 {
    height: 255px;
  }
  .row3 {
    height: 95px;
    line-height: 95px;
  }
  .row4 {
    height: 150px;
  }
  .row5 {
    height: 160px;
    font-size: 32px;
    text-align: left;
    .half {
      padding-left: 60px;
    }
  }
  .half {
    width: 50%;
    float: left;
    height: 100%;
    &:first-of-type {
      border-right: 1px solid #eae8e8;
    }
  }
  .third {
    width: 33%;
    float: left;
    height: 100%;
  }
  .third.middle {
    border-right: 1px solid #eae8e8;
    border-left: 1px solid #eae8e8;
  }
  span.degrees {
    font-size: 50px;
    font-weight: bold;
  }
  span.estimated_waiting_time_top, span.estimated_waiting_time {
    color: @css-red;
    font-size: 48px;
  }
  span.temperature {
    img {
      margin-left: auto;
      margin-right: auto;
    }
  }
  .bold {
    font-weight: bold;
  }
  .enlarge {
    font-size: 48px;
  }
  .inforow-last-updated {
    font-size: 22px; height: 55px; line-height: 55px;
  }
}

#screen-small {
  //background: green;
  .cableway-holder {
    height: 100vh;
    width: 100%;
    .cableway-panel {
      width: 1280px;
      top: 5%;
      transform: translateY(0);

    }
    p {
    }
    p.large {
      font-size: 120px * 0.7;
    }
    p.medium {
      font-size: 36px * 0.7;
    }

    .inforow-holder {
    }

    .status-heading {
      font-size: 100px * 0.7;
      line-height: 100px * 0.7;
    }

    .inforow {
      width: 1280px;
      font-size: 36px * 0.7;
    }

    .red {
    }

    .row1 {
      height: 55px * 0.7;
    }

    .row2 {
      height: 255px * 0.7;
    }
    .row3 {
      height: 95px * 0.7;
      line-height: 95px * 0.7;
    }
    .row4 {
      height: 150px * 0.7;
    }
    .row5 {
      height: 160px * 0.7;
      font-size: 32px * 0.7;
      .half {
        padding-left: 60px * 0.7;
      }
    }
    .half {
      width: 50%;
      height: 100%;
      &:first-of-type {
      }
    }
    .third {
      width: 33%;
      float: left;
      height: 100%;
    }
    .third.middle {
    }
    span.degrees {
      font-size: 50px * 0.7;
    }
    span.estimated_waiting_time_top, span.estimated_waiting_time {
      font-size: 48px * 0.7;
    }
    span.temperature {
      img {
        height: 170px;
      }
    }
    .bold {
    }
    .enlarge {
      font-size: 48px * 0.7;
    }

    .inforow-last-updated {
      font-size: 22px * 0.7; height: 55pxx * 0.7; line-height: 55px * 0.7;
    }
  }

  span.deg {
    font-size: 50px * 0.7;
  }
}


span.deg {
  font-size: 50px;
}




// ZIGZAGS???


.zigzag {
  position: relative;
  width: 100%;
  height: 200px;
}

.zigzag:before {
  content: "";
  display: block;
  position: absolute;
  bottom: -10px;
  width: 100%;
  height: 20px;
}

.zigzag:before {
  background: linear-gradient(45deg, #FFE400 33.333%, transparent 33.333%, transparent 66.667%, #FFE400 66.667%),
  linear-gradient(-45deg, #FFE400 33.333%, transparent 33.333%, transparent 66.667%, #FFE400 66.667%);
  background-size: 20px 40px;
}




// PROMOS


.promo-dialog {
  -webkit-transition: -webkit-transform 1000ms cubic-bezier(0.0,0.0,0.2,1);
  transition: transform 1000ms cubic-bezier(0.0,0.0,0.2,1);
  -webkit-transform: translateY(0);
  transform: translateY(0);
  margin-top: 180px;
  @media (min-width: 768px) {
    width: 800px;
  }
  .modal-content {
    .btn-primary {
      position: absolute;
      bottom: 75px;
      right: 35px;
      font-size: 23px;
    }
    .txt-more {
      color: @white;
      position: absolute;
      display: block;
      top: 174px;
      left: 55px;
    }
    .modal-header {
      position: absolute;
      margin: 0;
      right: 0;
      top: -5px;
      z-index: 9;
      .close {
        background: transparent;
        color: @white;
        width: 24px;
        height: 24px;
        line-height: 20px;
        filter: alpha(opacity=50);
        opacity: 0.5;
        border-radius: 24px;
        &:hover {
          filter: alpha(opacity=100);
          opacity: 1;
          span {
            color: @white;
          }
        }
        span {
          background: @black;
          border-radius: 5px;
          color: @white;
          display: block;
          height: 24px;
          width: 24px;
        }
      }
    }

    .modal-body {
      margin: 0;
      padding: 0;
      display: block;
      img {
        width: 100%;
      }
    }

    .cpe-find-out-more{
      //background: url("/assets-min/images/cpe-find-out-more.png") no-repeat;
      display: block;
      width: 130px;
      height: 60px;
      position: absolute;
      left: 20px;
      bottom: -25px;
      background-size: 100%;
      font-family: Holstein, sans-serif;
      color: #fff;
      font-size: 20px;
      line-height: 22px;
    }

    .cpe-book-now{
      background: url("/assets-min/images/cpe-book-now2.png") no-repeat;
      display: block;
      width: 144px;
      height: 144px;
      position: absolute;
      right: 11%;
      top: 24px;
      background-size: 100%;
    }
  }
  @media (max-width: 767px) {
    width: 500px;
    margin: 130px auto 30px;
    .modal-content {

      .cpe-find-out-more{
        bottom: -25px;
        width: 94px;
      }

      .cpe-book-now{
        width: 130px;
        height: 130px;
        top: 21px;
        right: 19px;
      }

      .modal-header {
        right: -8px;
        top: -8px;
      }
      .txt-more {
        top: 104px;
        left: 35px;
        font-size: 25px;
        width: 145px;
        line-height: 28px;
      }
      .btn-primary {
        position: absolute;
        bottom: 65px;
        font-size: 20px;
      }
    }
  }
  @media (max-width: 500px) {
    width: 340px;
    .modal-content {
      .cpe-find-out-more{
        bottom: -35px;
      }
      .cpe-book-now{
        width: 85px;
        height: 85px;
        top: 21px;
        right: 19px;
      }
      .modal-header {
        right: -10px;
        top: -15px;
      }
      .txt-more {
        top: 69px;
        left: 25px;
        font-size: 16px;
        width: 65px;
        line-height: 19px
      }
      .btn-primary {
        position: absolute;
        bottom: 35px;
        right: 25px;
        font-size: 15px;
      }
    }
  }
}


.media-primary {

  text-decoration:none !important;
  color:inherit;
  display:block;

  &:hover {
   background:#EFEFEF !important;
   color:inherit;

 }

}

.btn-block {}
// body {background:red !important}


/* hefdfd
llo*/
/* hello*/


.list-flex:hover, .list-flex:active  {
  text-decoration:none;

}


.circle-number {
  position: relative;
  background-color: @css-red;
  width: 30px;
  height: 30px;
  border-radius: 20px;
  .stop-number {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: @white;
    font-weight: 600;
  }
}

.key-indicator {
  background: rgba(0, 0, 0, .05);
  padding: 30px 40px 10px 40px;
  .key-heading {
    margin-top: 0;
  }
}

.embedded-modal {
 .close {
   display:none !important;
 }
 .modal-header {
  border:none !important;
  padding:0 !important;
}
.modal-dialog {
  width:100% !important;
  margin:0 !important;
}
.modal-content {
  border:none !important;
  box-shadow: none !important;
  background-color:transparent !important;
}

.modal-body {
  padding:0;
}

}


.reserved-items {
  position: fixed;
  bottom: 0;
  left: 0;
  background: #eee;
  width: 100%;
  text-align: center;
  padding: 10px;

  span {
    font-weight:700;
  }
}


.breadcrumb {
  font-size:12px !important;
  margin-bottom:0 !important;
  // box-shadow: 1px 1px 3px #292929;
  border-radius:0 !important;
  // z-index:10;
  background:white !important;
  border-bottom:1px solid #EFEFEF;
}

.media-object {

  img {
    border:1px solid #CCC !important;

  }
}

.float-right {
  float:right;
  margin-left:10px;
}

.float-left {
  float:left;
  margin-right:10px;
}

.product-mini-description {
  font-weight:bold;
}

.ui-datepicker .ui-datepicker-next {
  margin-top: 2px;
  &:after {
    .fa;
    display: inline-block;
    width: 20px;
    height: 20px;
    text-indent: 0;
    content: "\f054";
    //margin-top:2px;
    line-height:20px;
  }
}
.ui-datepicker .ui-datepicker-prev {
  margin-top: 2px;
  &:after {
    .fa;
    display: inline-block;
    width: 20px;
    height: 20px;
    text-indent: 0;
    content: "\f053";
    line-height:20px;
  }
}

.premium{
 position: absolute;
 bottom: 8px;
 right: 5px;
}

.product-mini.media-primary{
  //overflow: hidden;
}

.triangle-topleft-free {
  position: absolute;
  width: 0;
  height: 0;
  border-top: 65px solid red;
  border-right: 65px solid transparent;
  top: -20px;
  z-index: 2;
  p{
   top: -54px;
   left: 4px;
   font-size: 15px;
   font-weight: 700;
   color: #fff1f1;
   transform: rotate(-46deg) skew(-9deg,0);
   padding-left: 23px;
   margin-top: -31px;
   margin-left: -7px;
 }
}

.triangle-topleft-price-longer{
 position: absolute;
 width: 0;
 height: 0;
 border-top: 100px solid #ffe800;
 border-right: 100px solid transparent;
 top: -20px;
 left: 0;
 z-index: 1;
 p{
  top: -70px;
  -webkit-transform: rotate(-47deg) skew(-10deg,0);
  -ms-transform: rotate(-47deg) skew(-10deg,0);
  transform: rotate(-47deg) skew(-10deg,0);
  padding-left: 77px;
  margin-top: -67px;
  margin-left: -63px;
  width: 154px;
  line-height: 15px;
  font-size: 13px;
  font-weight: bold;
}
}


.triangle-topleft-price {
 position: absolute;
 width: 0;
 height: 0;
 border-top: 100px solid #ffe800;
 border-right: 100px solid transparent;
 top: -20px;
 left: 0;
 z-index: 1;

 &.no-line{
  p{
    text-decoration: none;
    

  }

 }


 p{
  top: -72px;
  transform: rotate(-47deg) skew(-10deg,0);
  padding-left: 23px;
  margin-top: -39px;
  margin-left: 3px;
  text-decoration: line-through;
}
}

// Sidebar ticket items

#cross-sell {

  .media {
    padding:5px;
  }

  .ticket-img {
    display:none !important;
  }

  .ticket-body {
    padding-left:0 !important;
  }
}

.upgrade-box {
  border:1px solid #CCC;
  padding:5px;
}


.cs-options{
  a{
    color:@css-red;
    &:hover{
      text-decoration: none;
      color: @css-red;
    }
  }
}
div.cs-skin-underline {
  background: transparent;
  font-size: 1.8em;
}

@media screen and (max-width: 30em) {
  div.cs-skin-underline { font-size: 1.2em; }
}

.cs-skin-underline > span {
  padding: 0.5em 3em 0.5em 0.5em;
  border-bottom: 3px solid #000;
  border-color: inherit;
  font-weight: bold;
  font-size: 22px;
}

.cs-skin-underline > span::after {
  font-family: 'icomoon';
  content: '\e003';
  right: 0.25em;
  -webkit-transform: translate3d(0,-50%,0) rotate3d(0,0,1,45deg);
  transform: translate3d(0,-50%,0) rotate3d(0,0,1,45deg);
  -webkit-transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
}

.cs-skin-underline.cs-active > span::after {
  -webkit-transform: translate3d(0,-50%,0) rotate3d(0,0,1,270deg);
  transform: translate3d(0,-50%,0) rotate3d(0,0,1,270deg);
}

.cs-skin-underline .cs-options {
  background: #bbc7c8;
  opacity: 0;
  -webkit-transition: opacity 0.3s 0.4s, visibility 0s 0.7s;
  transition: opacity 0.3s 0.4s, visibility 0s 0.7s;
}

.cs-skin-underline.cs-active .cs-options {
  opacity: 1;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.cs-skin-underline ul span {
  position: relative;
  text-transform: none;
  font-size: 66%;
  font-weight: 700;
  letter-spacing: 1px;
  padding: 2px 3px;
  font-size: 16px;
  opacity: 0;
  -webkit-transform: translate3d(100%,0,0);
  transform: translate3d(100%,0,0);
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
}

.cs-select ul span::after {
  content: '';
  opacity: 0;
}

.cs-select .cs-selected span::after {
  font-family: 'icomoon';
  content: '\e010';
  opacity: 1;
  -webkit-transition: opacity 0.3s 0.7s;
  transition: opacity 0.3s 0.7s;
}

.cs-skin-underline ul span::before {
  content: '';
  position: absolute;
  bottom: 1px;
  left: 0;
  height: 3px;
  width: 100%;
  background-color: #fff;
  -webkit-transform: translate3d(200%,0,0);
  transform: translate3d(200%,0,0);
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
}

.cs-skin-underline.cs-active ul span,
.cs-skin-underline.cs-active ul span::before {
  opacity: 1;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

.cs-skin-underline li:nth-child(5) span,
.cs-skin-underline li:nth-child(5) span::before,
.cs-skin-underline.cs-active li:first-child span,
.cs-skin-underline.cs-active li:first-child span::before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.cs-skin-underline li:nth-child(4) span,
.cs-skin-underline li:nth-child(4) span::before,
.cs-skin-underline.cs-active li:nth-child(2) span,
.cs-skin-underline.cs-active li:nth-child(2) span::before {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}

.cs-skin-underline li:nth-child(3) span,
.cs-skin-underline li:nth-child(3) span::before {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.cs-skin-underline li:nth-child(2) span,
.cs-skin-underline li:nth-child(2) span::before,
.cs-skin-underline.cs-active li:nth-child(4) span,
.cs-skin-underline.cs-active li:nth-child(4) span::before {
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}

.cs-skin-underline li:first-child span,
.cs-skin-underline li:first-child span::before,
.cs-skin-underline.cs-active li:nth-child(5) span,
.cs-skin-underline.cs-active li:nth-child(5) span::before {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
} /* more items require more delay declarations */

.cs-skin-underline .cs-options li span:hover,
.cs-skin-underline .cs-options li.cs-focus span,
.cs-skin-underline li.cs-selected span {
  color: #566473;
  background: transparent;
}

div.cs-select {
  -webkit-appearance: none;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  text-align: left;
  background: #fff;
  z-index: 100;
  width: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
// select{
//       display: block;
//     position: relative;
//     cursor: pointer;
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
//         border-bottom: 3px solid #000;
//     border-color: inherit;
//     font-weight: 700;
//     font-size: 22px;
// }
div.cs-select:focus {
  outline: none; /* For better accessibility add a style for this in your skin */
}

.cs-select select {
  display: none;
  -webkit-appearance: none;
}

.cs-select span {
  display: block;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Placeholder and selected option */
.cs-select > span {
  padding: 5px 0px;
}

.cs-select > span::after,
.cs-select .cs-selected span::after {
  speak: none;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cs-select > span::after {
  content: '\25BE';
  right: 0em;
}

.cs-select .cs-selected span::after {
  content: '\2713';
  margin-left: 1em;
}

.cs-select.cs-active > span::after {
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}

div.cs-active {
  z-index: 200;
}

/* Options */
.cs-select .cs-options {
  position: absolute;
  overflow: hidden;
  width: 100%;
  background: #fff;
  visibility: hidden;
  border: 1px solid #ddd;
}

.cs-select.cs-active .cs-options {
  visibility: visible;
}

.cs-select ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.cs-select ul span {
  //padding: 1em;
}

.cs-select ul li.cs-focus span {
  background-color: #ddd;
}

/* Optgroup and optgroup label */
.cs-select li.cs-optgroup ul {
  padding-left: 1em;
}

.cs-select li.cs-optgroup > span {
  cursor: default;
}

#dropdownMenuLink {
  border-bottom: 3px solid #000;
  border-color: inherit;
  font-weight: 700;
  font-size: 22px;
  border: 0;
  box-shadow: none;
  padding: 0;
  color: #000;
  border-radius: 0;
  width: 100%;
  text-align: left;

}

.other{
  border-bottom: 3px solid #000;
  .dropdown-menu{
    width: 100%;
    border-radius: 0;
  }
  a.dropdown-item{
    font-weight: 700;
    letter-spacing: 1px;
    padding: 2px 3px;
    font-size: 16px;
  }

  .open{
    .cs-placeholder::after {
      -webkit-transform: translateY(-50%) rotate(180deg);
      -ms-transform: translateY(-50%) rotate(180deg);
      transform: translateY(-50%) rotate(180deg);
    }

    .cs-placeholder::after {
      -webkit-transform: translate3d(0,-50%,0) rotate3d(0,0,1,270deg);
      transform: translate3d(0,-50%,0) rotate3d(0,0,1,270deg);
    }
  }

  .cs-placeholder::after {
    content: '\25BE';
    right: 0;
    speak: none;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: icomoon;
    -webkit-transform: translate3d(0,-50%,0) rotate3d(0,0,1,360deg);
    transform: translate3d(0,-50%,0) rotate3d(0,0,1,360deg);
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s,-webkit-transform .5s;
  }

  .other .cs-selected span::hover, .cs-select>span::hover {
    speak: none;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transform: translate3d(0,-50%,0) rotate3d(0,0,1,360deg);
    transform: translate3d(0,-50%,0) rotate3d(0,0,1,360deg);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .cs-skin-underline>span::after {
    font-family: icomoon;
    content: '\e003';
    right: .25em;
    -webkit-transform: translate3d(0,-50%,0) rotate3d(0,0,1,45deg);
    transform: translate3d(0,-50%,0) rotate3d(0,0,1,45deg);
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s,-webkit-transform .5s;
  }

}
.cs-select.selcat.cs-skin-underline{
  .cs-select.selcat.cs-skin-underline{
    display: none;
  }
}
.filter_attractions select {
  -webkit-appearance: none;
  border: none;
  background: none;
  display: block;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 3px solid #000;
  border-color: inherit;
  font-weight: 700;
  font-size: 22px;
  width: 100%;
  -webkit-border-radius: 0px;
}

#slideshow , #slideshow-none{   
  position: relative;  
}

#slideshow > div, #slideshow-none > div { 
  position: absolute; 
  .image{
    height: 500px;
    width: 1920px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
  }
}


.ui-datepicker td a {
  position: relative;
}
.ui-datepicker td a:after {
  content: "";
  display: block;
  text-align: center;
  color: Blue;
  font-size: 11px;
  font-weight: bold;
  position: absolute;
  bottom: 0;
  left: 0;
}
.modal-body .notice-item {
  &:last-child {
    margin-bottom: 10px;
  }
}
.notice-item {
  margin-bottom: 8px;
  padding: 5px 0;
  padding-left: 25px;
  position: relative;
  font-size: 14px;

  &:last-child {
    margin-bottom: 0px;
  }


  i.fa:first-child {
    position: absolute;
    left: 0;
    font-size: 20px;
    width: 20px;
    text-align: center;
  }
  img:first-child {
    position: absolute;
    left: 0;
    height: 28px;
    top: 0;
    text-align: center;
  }
  i.fa.fa-check {
    color: @alert-success;
  }
}

#concierge-info {
  font-size: 15px;
  font-weight: bold;
}

#privacy-and-cookie-dialog {
  z-index: 16000003;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;

  .alert {
    font-size: 14px;
    border-radius: 0;
    margin-bottom: 0;
  }
}
